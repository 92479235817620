.search-results {
    .event-results-map {
        #map {
            height: 500px;
        }

        .event-results-no-geolocation {
            position: absolute;
        }
    }
}

.event-calendar-html-marker {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    height: 32px;
    width: 32px;
    background-color: transparent;

    > .icon {
        width: 32px;
        height: 32px;
    }
}

#map {
    height: 300px;

    .event-marker {
        background-image: url(leaftlet/images/default-marker.svg) ;
    }

    .leaflet-container {
        height: 100%;
    }

    .event-info {
        width: 300px;
        height: 90px;
        margin: 10px;
        background-color: #fff;
        border: 2px solid #fff;
        border-radius: 3px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
        @include respond-to(xs) {
            width: 220px;
            .event-info-icons {
                display: none;
            }
        }

        > .event-info-content {
            width: 220px;
            height: 100%;
            padding: 10px;
            float: left;

            .event-title {
                overflow: hidden;
                font-weight: bold;
                font-size: 14px;
                color: black;
            }

            .event-location {
                font-size: 12px;
                margin-top: 10px;
            }

            .event-info-link {
                position: absolute;
                bottom: 15px;
                font-size: 12px;
            }
        }

        .event-info-icons {
            float: right;
            padding-right: 10px;
            width: 50px;
            height: 80px;
            text-align: right;

            .icon-nav {
                width: 22px;
                height: 22px;
                background-image: url(https://maps.gstatic.com/mapfiles/embed/images/entity11.png);
            }
        }
    }
}

.event-map {
    .count-search-results {
        display: none;
    }
}

.event-map-cluster-icon--ew {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNy45LjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA1NzEuNSA1NzEuNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTcxLjUgNTcxLjU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZDMDA7fQ0KCS5zdDF7ZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7fQ0KCS5zdDJ7c3Ryb2tlOiMwMDAwMDA7c3Ryb2tlLXdpZHRoOjYuNTI7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30NCjwvc3R5bGU+DQo8ZyBpZD0iY2x1c3Rlci0tLWtyZWlzIj4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTY1LjUsMjg1LjdjMCwxNTQuNS0xMjUuMywyNzkuOC0yNzkuOCwyNzkuOFM1LjksNDQwLjIsNS45LDI4NS43UzEzMS4yLDUuOSwyODUuNyw1LjkNCgkJUzU2NS41LDEzMS4yLDU2NS41LDI4NS43eiIvPg0KCTxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik01NzEuNSwyODUuN0M1NzEuNSwxMjcuOSw0NDMuNSwwLDI4NS43LDBTMCwxMjcuOSwwLDI4NS43czEyNy45LDI4NS43LDI4NS43LDI4NS43UzU3MS41LDQ0My41LDU3MS41LDI4NS43eg0KCQkgTTU1OS42LDI4NS43YzAsMTUxLjItMTIyLjYsMjczLjgtMjczLjgsMjczLjhTMTEuOSw0MzcsMTEuOSwyODUuN1MxMzQuNSwxMS45LDI4NS43LDExLjlTNTU5LjYsMTM0LjUsNTU5LjYsMjg1LjdMNTU5LjYsMjg1Ljd6Ig0KCQkvPg0KCTxwYXRoIGNsYXNzPSJzdDIiIGQ9Ik00NzQuNCwyODUuN2MwLDEwMC41LTg0LjQsMTgyLTE4OC42LDE4MnMtMTg4LjYtODEuNS0xODguNi0xODJzODQuNC0xODIsMTg4LjYtMTgyUzQ3NC40LDE4NS4yLDQ3NC40LDI4NS43DQoJCUw0NzQuNCwyODUuN3oiLz4NCjwvZz4NCjwvc3ZnPg0K);
    font-size: 14px;
    line-height: 36px;
    font-family: Gudea, sans-serif;
    text-align: center;
}

.event-map--ew,
.event-calendar--ew {
    .leaflet-container {
        .leaflet-popup {
            .leaflet-popup-content-wrapper {
                padding-left: 0;
                padding-right: 0;

                .leaflet-popup-content {
                    max-width: 300px;

                    margin-left: 0;
                    margin-right: 0;

                    font-size: 14px;
                    font-weight: 300;

                    .infowindow-content {
                        > div:first-of-type,
                        .img ~ *
                        {
                            margin-left: 12px;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
}
